@use "../../styles/mixins" as *;
@include layer(ui) {
    .loading-skeleton {
        position: relative;
        box-sizing: border-box;
        height: 50px;
        width: 100%;
        background: var(--c-loading-skeleton-color);
        fill: var(--c-loading-skeleton-color);
        border: transparent solid var(--g-border-width-xs);
        border-radius: var(--c-loading-skeleton-border-radius);
        animation: loading-skeleton-animation 1200ms ease infinite alternate;
    }

    @keyframes loading-skeleton-animation {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0.2;
        }
    }
}
