@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .grid {
        margin-top: var(--g-spacing-lg);
        margin-bottom: var(--g-spacing-lg);
        @include breakpoint-md {
            margin-bottom: var(--g-spacing-xl);
        }
    }

    .article {
        display: flex;
        gap: var(--g-spacing-md);

        .icon {
            flex-shrink: 0;
        }

        .content {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: var(--g-spacing-sm);
        }
    }
}
