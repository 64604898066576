.page {
    text-align: center;
}

.profile-image {
    margin: auto;
    padding-bottom: var(--g-spacing-md);
    max-width: 300px;
}

.bio {
    margin: var(--g-spacing-lg) auto var(--g-spacing-xl);
    max-width: 540px;
    text-align: left;
}
