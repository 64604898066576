@use "@wojo/ui/mixins" as *;

.hero {
    height: 300px;
    position: relative;

    @include breakpoint-md {
        height: 450px;
    }
}

.column {
    background-color: white;
    padding-bottom: var(--g-spacing-md);
    padding-top: var(--g-spacing-md);
    @include breakpoint-md {
        padding-top: var(--g-spacing-xl);
        padding-bottom: var(--g-spacing-xl);
        &-with-hero {
            margin-top: -100px;
            position: relative;
        }
    }
}

.column-inner {
    margin: auto;
    max-width: var(--c-container-sm);
}

.byline {
    align-content: center;
    display: flex;
    margin-top: var(--g-spacing-md);
    gap: var(--g-spacing-md);
}

.authoring {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-xs);
    justify-content: center;
}

.byline-image {
    flex: 0 0 60px;
    @include breakpoint-sm {
        flex: 0 0 70px;
    }
}

.tags {
    border-top: 1px solid var(--s-color-separator-standard);
    display: flex;
    gap: var(--g-spacing-sm);
    padding-top: var(--g-spacing-md);
}

.author-link {
    text-decoration: none;
}

.author {
    display: flex;
    gap: var(--g-spacing-md);
    padding-top: var(--g-spacing-xl);
    text-decoration: none;
}

.author-image {
    flex: 0 0 80px;
    @include breakpoint-sm {
        flex: 0 0 100px;
    }
}

.bio {
    margin-top: var(--g-spacing-lg);
}
