@use "../../styles/mixins" as *;
@include layer(ui) {
    .task-label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        box-sizing: border-box;
        flex-shrink: 0;
        white-space: nowrap;
        min-height: 22px;
        padding: var(--g-spacing-xxs) var(--g-spacing-sm);
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-bold);
        letter-spacing: var(--g-letter-spacing-loose);
        line-height: var(--g-line-height-200);
        font-size: var(--g-font-size-200);
        border-radius: var(--c-task-label-border-radius);
    }

    .primary {
        &--alert {
            background-color: var(--s-color-alert-standard);
            color: var(--s-color-text-inverse);
            border: var(--g-border-width-xs) solid transparent;
        }

        &--success {
            background-color: var(--s-color-success-standard);
            color: var(--s-color-text-inverse);
            border: var(--g-border-width-xs) solid transparent;
        }
    }

    .secondary {
        &--alert {
            background-color: transparent;
            color: var(--s-color-alert-standard);
            border: var(--g-border-width-xs) solid var(--s-color-alert-standard);
        }

        &--success {
            background-color: transparent;
            color: var(--s-color-success-standard);
            border: var(--g-border-width-xs) solid
                var(--s-color-success-standard);
        }
    }
}
